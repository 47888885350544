<template>
  <!-- Layout Header -->
  <a-layout-header class="layout-header" :style="{ height: device.mobile ? '50px' : '74px' }">
    <a-row>
      <a-col :span="24">
        <div class="letwecare-breadcrumb">
          <a-breadcrumb
            v-if="!device.mobile"
            class="__breadcrumb"
            :routes="breadcrumbRoutes"
          >
            <template #itemRender="{ routes, route }">
              <span v-if="routes.indexOf(route) === routes.length - 1">
                {{ route.breadcrumbName }}
              </span>
              <router-link v-else :to="route.path">
                {{ route.breadcrumbName }}
              </router-link>
            </template>
          </a-breadcrumb>

          <a-page-header
            class="__page-header"
            style="height: 63px; padding-left: 0;"
            :title="`${courseData.name}：即時調查 - ${instantSurveyData.name}`"
            @back="backToInstantSurveyPage"
          >
            <template #itemRender="{ route, params, routes, paths }">
              <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
              <router-link v-else :to="paths.join('/')">{{route.breadcrumbName}}</router-link>
            </template>
          </a-page-header>
        </div>
      </a-col>
    </a-row>
  </a-layout-header>
  <!-- Layout Header -->

  <!-- Layout Body -->
  <a-layout class="app-layout">
    <a-card class="instant-survey-page-content">
      <a-tabs
        v-model:activeKey="selectedKey"
        @change="handleTabChange"
        centered
      >
        <a-tab-pane key="editor" tab="編輯" />
        <a-tab-pane key="statistic" tab="統計" />
        <a-tab-pane key="setting" tab="設定" />
      </a-tabs>
      
      <div class="content__body">
        <router-view />
      </div>
    </a-card>
  </a-layout>
  <!-- Layout Body -->
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { message, Empty } from 'ant-design-vue'
import { green, red } from '@ant-design/colors'
import { createDeviceDetector } from 'next-vue-device-detector'
import { LikeFilled, LikeOutlined } from '@ant-design/icons-vue'
import { useAuthStore } from '@/store/auth'
import { useInstantSurveyStore } from './store/index'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();
    const instantSurveyStore = useInstantSurveyStore();

    /* Tab */
    const selectedKey = ref('editor');
    const handleTabChange = (async () => {
      if (selectedKey.value === 'editor') {
        router.push({ name: 'AppCourseInstantSurveyIdPageEditor' });
      }
      else if (selectedKey.value === 'statistic') {
        router.push({ name: 'AppCourseInstantSurveyIdPageStatistic' });
      }
      else if (selectedKey.value === 'setting') {
        router.push({ name: 'AppCourseInstantSurveyIdPageSetting' });
      }
      

      // loading.value = true;
      
      // await getCourseSurveyData();
      // formState.value = { ...surveyData.value[selectedKey.value] };

      // loading.value = false;
    });

    /* Loading */
    const loading = ref(true);

    /* Data */
    const courseData = ref({});
    const instantSurveyData = ref({});

    /* Api */
    const getCourseData = (async () => {
      const response = await api.v1.course.findOne(route.params.course_id);
      courseData.value = response.data.result;
      console.log(courseData.value);
    });

    const getInstantSurveyData = (async () => {
      const response = await api.v1.course.findOneInstantSurvey(route.params.course_id, route.params.id);
      instantSurveyStore.setData({ data: response.data.result });
      instantSurveyData.value = { ...response.data.result };
      console.log(instantSurveyStore.data);
      console.log(instantSurveyData.value)
    });

    /* Routes breadcrumb */
    const breadcrumbRoutes = ref([
      {
        path: '/app/courses',
        breadcrumbName: '班級管理'
      }
    ]);

    /* Mounted */
    onMounted(async () => {
      await getCourseData();
      await getInstantSurveyData();

      // set routes breadcrumb
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }`,
        breadcrumbName: courseData.value.name
      });
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }/instant-survey`,
        breadcrumbName: '即時調查'
      });
      breadcrumbRoutes.value.push({
        path: `/app/course/${ route.params.course_id }/instant-survey/${ route.params.instant_survey_id }`,
        breadcrumbName: instantSurveyData.value.name
      });

      loading.value = false;
    });

    return {
      device,

      /* Store */
      authStore,
      instantSurveyStore,

      /* Tab */
      selectedKey,
      handleTabChange,

      /* Routes breadcrumb */
      breadcrumbRoutes,

      /* Loading */
      loading,

      /* Data */
      courseData,
      instantSurveyData,
    }
  },
  methods: {
    /* Router */
    backToInstantSurveyPage() {
      this.$router.push({
        name: 'AppCourseInstantSurveyPage',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },
  }
})
</script>

<style lang="scss">
.instant-survey-page-content {
  width: 100%;
  height: 100%;

  & > .ant-card-body {
    padding: 0 12px !important;

    /* md */
    @media screen and (min-width: 970px) {
      padding: 8px 24px 0 24px !important;
    }
  }

  .content__body {
    height: calc(100vh - 120px);
    padding-bottom: 20px;
    overflow: auto;

    /* md */
    @media screen and (min-width: 970px) {
      height: calc(100vh - 145px);
    }
  }
}
</style>